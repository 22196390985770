import { forEach } from '@technically/lodash'
import { createRoot } from 'react-dom/client'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'

import * as controlTreeDebugUtils from '~p/client/control-tree/debugUtils'
import { openUnhandledErrorAlert } from '~p/client/common/actions'

import Root from './components/Root'
import store from './store'
import controlTree from './controlTree'

import snapshots from './snapshots'

// For debugging.
window.store = store
window.snapshots = snapshots

// One should be using control-tree/debugUtils via window like window.getNodes().
window.getControlTree = () => controlTree
forEach(controlTreeDebugUtils.createUtils(store, controlTree), (fn, name) => {
  window[name] = fn
})

viewportUnitsBuggyfill.init()

// Handle error (this won't automatically catch errors from promises, see below).
window.onerror = () => {
  store.dispatch(openUnhandledErrorAlert())

  // Do not suppress errors.
  return false
}

createRoot(document.getElementById('root')).render(<Root store={store} />)
