import * as _ from '@technically/lodash'

import { GLOVE_ASSET_DICT, LEATHERS, WEBS } from '../../common/sheets'
import generateSnapshots from './generate'
import controlTree from '../controlTree'

const HEIGHT = 512

const getJob = ({ filename, leather, glove, web, viewName }) => {
  const controlTreeChanges = [
    ['product.leather', leather.id],
    ['product.glove', glove.id],
    ['colors.web.style', web.id],
    ['colors.laces.fingerWeb', 'columbiaBlue'],
    ['colors.laces.heel', 'scarlet'],
  ]

  const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
  const sourceSize = gloveAsset.props.imageDimensions[viewName]
  const height = HEIGHT
  const width = Math.round((height / sourceSize.height) * sourceSize.width)
  const size = {
    width,
    height,
  }

  return { filename, controlTreeChanges, viewName, size }
}

const main = async (filter) => {
  const state = {
    controlTree: {
      pendingChanges: { auto: {}, user: {} },
      preferredValues: {},
      repeatedNodes: {},
      values: {},
    },
  }

  const gloves = controlTree.getNodes(state)['product.glove'].visibleOptions
  const leathers = LEATHERS
  const webs = WEBS

  let jobs = []
  const jobIds = []
  _.forEach(gloves, (glove) => {
    const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
    _.forEach(leathers, (leather) => {
      if (!glove.limitations.leatherId[leather.id]) {
        return
      }
      _.forEach(webs, (web) => {
        if (!glove.limitations.webIds[web.id]) {
          return
        }
        const jobId = `${gloveAsset.id}-${web.id}`
        if (_.includes(jobIds, jobId)) {
          return
        }
        jobIds.push(jobId)

        const filename = `${gloveAsset.id}   ${web.id}   ${leather.props.sku}${glove.id}`
        const job = getJob({
          filename,
          leather,
          glove,
          web,
          viewName: 'palm',
        })
        jobs.push(job)
      })
    })
  })
  if (filter) {
    jobs = _.filter(jobs, (job) => job.filename.match(filter))
  }
  console.log('Jobs:')
  console.log(jobs)
  return generateSnapshots(jobs)
}

export default main
