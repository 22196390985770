// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ShellBackMaterialRow } from '../typings';

const data = [
  {
    "id": "carbonFiberMesh",
    "name": "Hyper Shell",
    "props": {
      "materialIdSuffix": "carbonFiber",
      "hasExtraStitching": true
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": false,
        "deco": false
      }
    }
  },
  {
    "id": "carbonFiberBlack",
    "name": "Black Hyper Shell",
    "props": {
      "materialIdSuffix": "carbonFiberBlack",
      "hasExtraStitching": true
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true
      }
    }
  },
  {
    "id": "speedShellMesh",
    "name": "Black Speed Shell",
    "props": {
      "materialIdSuffix": "speedShell",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true
      }
    }
  },
  {
    "id": "speedShellCamel",
    "name": "Camel Speed Shell",
    "props": {
      "materialIdSuffix": "speedShellCamel",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true
      }
    }
  },
  {
    "id": "zebraKnitMesh",
    "name": "Zebra Knit",
    "props": {
      "materialIdSuffix": "zebraKnit",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": false
      }
    }
  },
  {
    "id": "blackCrocMesh",
    "name": "Black Croc",
    "props": {
      "materialIdSuffix": "croc",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true
      }
    }
  },
  {
    "id": "blackMesh",
    "name": "Black Mesh",
    "props": {
      "materialIdSuffix": "black",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true
      }
    }
  },
  {
    "id": "brownMesh",
    "name": "Brown Mesh",
    "props": {
      "materialIdSuffix": "brown",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true
      }
    }
  },
  {
    "id": "camoMesh",
    "name": "Camo Mesh",
    "props": {
      "materialIdSuffix": "camo",
      "hasExtraStitching": false
    },
    "subsets": {
      "shellBackDesignId": {
        "leather": false,
        "pro": true,
        "deco": true
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ShellBackMaterialRow[]>> = data;

export type ShellBackMaterial = typeof typed[number];

export const SHELL_BACK_MATERIAL_INDEX_KEY = "id";
export type ShellBackMaterialIndexKey = "id";
export type ShellBackMaterialId = ShellBackMaterial["id"];

let i = 0;
export const SHELL_BACK_MATERIAL_DICT = {
  "carbonFiberMesh": typed[i++],
  "carbonFiberBlack": typed[i++],
  "speedShellMesh": typed[i++],
  "speedShellCamel": typed[i++],
  "zebraKnitMesh": typed[i++],
  "blackCrocMesh": typed[i++],
  "blackMesh": typed[i++],
  "brownMesh": typed[i++],
  "camoMesh": typed[i++]
} as const;

export { typed as SHELL_BACK_MATERIALS };
