// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { LeatherColorRow } from '../typings';

const data = [
  {
    "name": "Tone On Tone",
    "id": "toneOnTone",
    "description": "stitching colors matched to leather colors",
    "props": {
      "hex": "#a9b6bc",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Gold Metallic",
    "id": "metallicGold",
    "props": {
      "hex": "#be8738",
      "isMetallic": true,
      "tileImageSrc": "icons/metallicColor/metallicGold.png"
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Silver Metallic",
    "id": "metallicSilver",
    "props": {
      "hex": "#757373",
      "isMetallic": true,
      "tileImageSrc": "icons/metallicColor/metallicSilver.png"
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Black Dry",
    "id": "blackDry",
    "props": {
      "hex": "#242120",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Black",
    "id": "black",
    "props": {
      "hex": "#1f1c1c",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": true,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": true,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Horween Black Oiled",
    "id": "blackOiled",
    "props": {
      "hex": "#191818",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Horween Black Soft Dual Core",
    "id": "blackSoftDualCore",
    "props": {
      "hex": "#141413",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Camel",
    "id": "camel",
    "props": {
      "hex": "#cda96b",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Dark Green",
    "id": "darkGreen",
    "props": {
      "hex": "#2a3e32",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Gray",
    "id": "gray",
    "props": {
      "hex": "#75716c",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Gray",
    "id": "libertyGray",
    "props": {
      "hex": "#888887",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Navy",
    "id": "navy",
    "props": {
      "hex": "#1b1c1e",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Orange",
    "id": "orange",
    "props": {
      "hex": "#cf5608",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Royal",
    "id": "royal",
    "props": {
      "hex": "#1b2c59",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Scarlet",
    "id": "scarlet",
    "props": {
      "hex": "#9e1714",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Tan",
    "id": "tan",
    "props": {
      "hex": "#a95313",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Timberglaze",
    "id": "timberglaze",
    "props": {
      "hex": "#7e4019",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Chocolate Brown",
    "id": "chocolate",
    "props": {
      "hex": "#31201a",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Gold Tan",
    "id": "goldTan",
    "props": {
      "hex": "#d07605",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Dark Sherry",
    "id": "primoSherry",
    "props": {
      "hex": "#542825",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Columbia Blue",
    "id": "columbiaBlue",
    "props": {
      "hex": "#5c8fb4",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Sandlot",
    "id": "sandlot",
    "props": {
      "hex": "#35241f",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Pink",
    "id": "pink",
    "props": {
      "hex": "#ce7c85",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "White",
    "id": "white",
    "props": {
      "hex": "#c9c6bb",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": true,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "JT Dark Shadow",
    "id": "jtDarkShadow",
    "props": {
      "hex": "#29241d",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Butterscotch",
    "id": "butterscotch",
    "props": {
      "hex": "#ab6919",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Golden Brown",
    "id": "goldenBrown",
    "props": {
      "hex": "#78451b",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Dark Tan",
    "id": "darkTan",
    "props": {
      "hex": "#572617",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": true,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Graphite",
    "id": "graphite",
    "props": {
      "hex": "#3a3a3e",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Cork",
    "id": "cork",
    "props": {
      "hex": "#8f654a",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Mocha (Dark Brown)",
    "id": "mochaDarkBrown",
    "props": {
      "hex": "#231d1c",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Dark Brown",
    "id": "darkBrown",
    "props": {
      "hex": "#231d1c",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Red Orange",
    "id": "redOrange",
    "props": {
      "hex": "#c12517",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Brown (Bruciato)",
    "id": "brownBruciato",
    "props": {
      "hex": "#6c2918",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Rich Tan",
    "id": "richTan",
    "props": {
      "hex": "#ac5313",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Brown",
    "id": "brown",
    "props": {
      "hex": "#53281d",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Maroon",
    "id": "maroon",
    "props": {
      "hex": "#533131",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Purple",
    "id": "purple",
    "props": {
      "hex": "#4a2366",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Chocolate Brown",
    "id": "chocolateBrown",
    "props": {
      "hex": "#534139",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Yellow",
    "id": "yellow",
    "props": {
      "hex": "#d9cb17",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Burgundy",
    "id": "primoBurgundy",
    "props": {
      "hex": "#302423",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Beige",
    "id": "beige",
    "props": {
      "hex": "#b09362",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": true,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Copper",
    "id": "copper",
    "props": {
      "hex": "#783e0e",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Gold",
    "id": "gold",
    "props": {
      "hex": "#806826",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Ivory",
    "id": "ivory",
    "props": {
      "hex": "#c6bea1",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Optic Yellow",
    "id": "opticYellow",
    "props": {
      "hex": "#d4ed19",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Primo Burgundy",
    "id": "primoBurgundyThread",
    "props": {
      "hex": "#300f12",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Silver",
    "id": "silver",
    "props": {
      "hex": "#919191",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Vegas Gold",
    "id": "vegasGold",
    "props": {
      "hex": "#b28e24",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Mint",
    "id": "mint",
    "props": {
      "hex": "#92b599",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": true,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Ocean Mint",
    "id": "oceanMint",
    "props": {
      "hex": "#92b599",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": true,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Coral",
    "id": "coral",
    "props": {
      "hex": "#e58f78",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": true
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Split Black",
    "id": "splitBlack",
    "props": {
      "hex": "#141413",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": false
      }
    }
  },
  {
    "name": "Split Gray",
    "id": "splitGray",
    "props": {
      "hex": "#75716c",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": true,
        "binding": false
      }
    }
  },
  {
    "name": "Mustard",
    "id": "mustard",
    "props": {
      "hex": "#a68300",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": true,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Red",
    "id": "red",
    "props": {
      "hex": "#ab060d",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": true,
        "embroidery": false
      },
      "pcfp": {
        "shell": false,
        "web": false,
        "trim": false,
        "lining": false,
        "welting": false,
        "binding": false
      }
    }
  },
  {
    "name": "Military Green",
    "id": "militaryGreen",
    "props": {
      "hex": "#485637",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  },
  {
    "name": "Teal",
    "id": "teal",
    "props": {
      "hex": "#0f7a6e",
      "isMetallic": false
    },
    "subsets": {
      "common": {
        "laces": false,
        "thread": false,
        "handSewnStitching": false,
        "embroidery": false
      },
      "pcfp": {
        "shell": true,
        "web": true,
        "trim": true,
        "lining": false,
        "welting": true,
        "binding": true
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<LeatherColorRow[]>> = data;

export type LeatherColor = typeof typed[number];

export const LEATHER_COLOR_INDEX_KEY = "id";
export type LeatherColorIndexKey = "id";
export type LeatherColorId = LeatherColor["id"];

let i = 0;
export const LEATHER_COLOR_DICT = {
  "toneOnTone": typed[i++],
  "metallicGold": typed[i++],
  "metallicSilver": typed[i++],
  "blackDry": typed[i++],
  "black": typed[i++],
  "blackOiled": typed[i++],
  "blackSoftDualCore": typed[i++],
  "camel": typed[i++],
  "darkGreen": typed[i++],
  "gray": typed[i++],
  "libertyGray": typed[i++],
  "navy": typed[i++],
  "orange": typed[i++],
  "royal": typed[i++],
  "scarlet": typed[i++],
  "tan": typed[i++],
  "timberglaze": typed[i++],
  "chocolate": typed[i++],
  "goldTan": typed[i++],
  "primoSherry": typed[i++],
  "columbiaBlue": typed[i++],
  "sandlot": typed[i++],
  "pink": typed[i++],
  "white": typed[i++],
  "jtDarkShadow": typed[i++],
  "butterscotch": typed[i++],
  "goldenBrown": typed[i++],
  "darkTan": typed[i++],
  "graphite": typed[i++],
  "cork": typed[i++],
  "mochaDarkBrown": typed[i++],
  "darkBrown": typed[i++],
  "redOrange": typed[i++],
  "brownBruciato": typed[i++],
  "richTan": typed[i++],
  "brown": typed[i++],
  "maroon": typed[i++],
  "purple": typed[i++],
  "chocolateBrown": typed[i++],
  "yellow": typed[i++],
  "primoBurgundy": typed[i++],
  "beige": typed[i++],
  "copper": typed[i++],
  "gold": typed[i++],
  "ivory": typed[i++],
  "opticYellow": typed[i++],
  "primoBurgundyThread": typed[i++],
  "silver": typed[i++],
  "vegasGold": typed[i++],
  "mint": typed[i++],
  "oceanMint": typed[i++],
  "coral": typed[i++],
  "splitBlack": typed[i++],
  "splitGray": typed[i++],
  "mustard": typed[i++],
  "red": typed[i++],
  "militaryGreen": typed[i++],
  "teal": typed[i++]
} as const;

export { typed as LEATHER_COLORS };
