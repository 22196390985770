import { some } from '@technically/lodash'

import getAsset from '../../../platform/getAsset'

import { LEATHERS, GLOVES } from './sheets'

const DESCRIPTION = 'Design your own Easton custom glove. '

const getProductTitle = () => 'Custom Easton Glove'

const getIndexTitle = () => 'Easton Glove Builder'

const getIndexDescription = () => DESCRIPTION

const getSkuDescription = () => DESCRIPTION

const getRecipeDescription = () => DESCRIPTION

const getVia = (config) => {
  if (config.hideVia || config.vendorName == null) {
    return ''
  }
  return ` via ${config.vendorName}`
}

const getSkuValues = (sku) => {
  const upperSku = sku.toUpperCase()
  let result
  some(LEATHERS, (leather) =>
    some(GLOVES, (glove) => {
      const canonicalSku = leather.props.sku + glove.name

      if (upperSku === canonicalSku) {
        result = {
          leather,
          glove,
          canonicalSku,
        }
        return true
      }

      return false
    }),
  )
  if (!result) {
    throw new Error(`SKU is not valid: "${sku}"`)
  }

  return result
}

const getSkuLabel = (leather, glove, shellBackDesign) => {
  const prefix = leather.props.sku
  const suffix = shellBackDesign.props?.skuSuffix ?? ''

  return `${prefix}${glove.name}${suffix}`
}

const getSkuTitle = (config, params) => {
  const [sku, nodes] = params

  const skuLabel = (nodes && nodes['calc.skuLabel'].value) ?? sku

  return `${skuLabel} - ${getProductTitle(config)} Template${getVia(config)}`
}

const getRecipeTitle = (config, _recipe, id, hasChanges) =>
  `Design #${id}${hasChanges ? ' with changes' : ''} - ${getProductTitle(
    config,
  )}${getVia(config)}`

const getStaticTags = () =>
  `
    <link rel="shortcut icon" href="${getAsset('favicon.ico')}">
    <link rel="icon" type="image/png" sizes="32x32" href="${getAsset(
      'favicon-32x32.png',
    )}">
    <link rel="icon" type="image/png" sizes="16x16" href="${getAsset(
      'favicon-16x16.png',
    )}">
    <link rel="apple-touch-icon" sizes="180x180" href="${getAsset(
      'apple-touch-icon.png',
    )}">
    <link rel="manifest" href="${getAsset('site.webmanifest')}">
    <meta name="msapplication-TileColor" content="#858585">
    <meta name="theme-color" content="#858585">
  `

const garmentSizes = []

export {
  getIndexTitle,
  getIndexDescription,
  getSkuValues,
  getSkuLabel,
  getSkuTitle,
  getSkuDescription,
  getRecipeTitle,
  getRecipeDescription,
  getStaticTags,
  garmentSizes,
}
