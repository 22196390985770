import * as _ from '@technically/lodash'

import { GLOVE_ASSET_DICT, GLOVES, LEATHERS } from '../../common/sheets'
import generateSnapshots from './generate'

const HEIGHT = 512

const getJob = ({ filename, leather, glove, viewName }) => {
  const controlTreeChanges = [
    ['product.leather', leather.id],
    ['product.glove', glove.id],
    ['colors.laces.fingerWeb', 'columbiaBlue'],
    ['colors.laces.heel', 'scarlet'],
    ['personalization.embroidery.color', 'scarlet'],
    ['personalization.embroidery.number.text', '55'],
    ['personalization.embroidery.number.font', 'blockNumber'],
  ]

  const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
  const sourceSize = gloveAsset.props.imageDimensions[viewName]
  const height = HEIGHT
  const width = Math.round((height / sourceSize.height) * sourceSize.width)
  const size = {
    width,
    height,
  }

  return { filename, controlTreeChanges, viewName, size }
}

const main = async (filter) => {
  const gloves = GLOVES
  const leathers = LEATHERS

  let jobs = []
  const jobIds = []
  _.forEach(gloves, (glove) => {
    const gloveAsset = GLOVE_ASSET_DICT[glove.asset.gloveAssetId]
    _.forEach(leathers, (leather) => {
      if (!glove.limitations.leatherId[leather.id]) {
        return
      }
      const jobId = gloveAsset.id
      if (_.includes(jobIds, jobId)) {
        return
      }
      jobIds.push(jobId)
      const filename = `${gloveAsset.id}   ${leather.props.sku}${glove.id}`
      const job = getJob({
        filename,
        leather,
        glove,
        viewName: 'web',
      })
      jobs.push(job)
    })
  })
  if (filter) {
    jobs = _.filter(jobs, (job) => job.filename.match(filter))
  }
  console.log('Jobs:')
  console.log(jobs)
  return generateSnapshots(jobs)
}

export default main
