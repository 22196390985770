// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PatchColorRow } from '../typings';

const data = [
  {
    "id": "EFEWG",
    "name": "White on Gray",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "40"
    },
    "leatherColorId": "white"
  },
  {
    "id": "EFEWB",
    "name": "White on Black",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "41"
    },
    "leatherColorId": "white"
  },
  {
    "id": "EFEGW",
    "name": "Gray on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "42"
    },
    "leatherColorId": "gray"
  },
  {
    "id": "EFEBW",
    "name": "Black on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "43"
    },
    "leatherColorId": "black"
  },
  {
    "id": "EFEPBW",
    "name": "Burgundy on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "44"
    },
    "leatherColorId": "primoBurgundy"
  },
  {
    "id": "EFESW",
    "name": "Scarlet on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "45"
    },
    "leatherColorId": "scarlet"
  },
  {
    "id": "EFEOW",
    "name": "Orange on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "46"
    },
    "leatherColorId": "orange"
  },
  {
    "id": "EFEYW",
    "name": "Yellow on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "47"
    },
    "leatherColorId": "yellow"
  },
  {
    "id": "EFECW",
    "name": "Camel on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "48"
    },
    "leatherColorId": "camel"
  },
  {
    "id": "EFEDGW",
    "name": "Dark Green on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "49"
    },
    "leatherColorId": "darkGreen"
  },
  {
    "id": "EFEMW",
    "name": "Mint on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "50"
    },
    "leatherColorId": "mint"
  },
  {
    "id": "EFECBW",
    "name": "Columbia Blue on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "51"
    },
    "leatherColorId": "columbiaBlue"
  },
  {
    "id": "EFERW",
    "name": "Royal on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "52"
    },
    "leatherColorId": "royal"
  },
  {
    "id": "EFENW",
    "name": "Navy on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "53"
    },
    "leatherColorId": "navy"
  },
  {
    "id": "EFEPW",
    "name": "Purple on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "54"
    },
    "leatherColorId": "purple"
  },
  {
    "id": "EFEPKW",
    "name": "Pink on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "55"
    },
    "leatherColorId": "pink"
  },
  {
    "id": "EFEBRW",
    "name": "Brown on White",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    },
    "asset": {
      "patchAssetId": "56"
    },
    "leatherColorId": "brown"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PatchColorRow[]>> = data;

export type PatchColor = typeof typed[number];

export const PATCH_COLOR_INDEX_KEY = "id";
export type PatchColorIndexKey = "id";
export type PatchColorId = PatchColor["id"];

let i = 0;
export const PATCH_COLOR_DICT = {
  "EFEWG": typed[i++],
  "EFEWB": typed[i++],
  "EFEGW": typed[i++],
  "EFEBW": typed[i++],
  "EFEPBW": typed[i++],
  "EFESW": typed[i++],
  "EFEOW": typed[i++],
  "EFEYW": typed[i++],
  "EFECW": typed[i++],
  "EFEDGW": typed[i++],
  "EFEMW": typed[i++],
  "EFECBW": typed[i++],
  "EFERW": typed[i++],
  "EFENW": typed[i++],
  "EFEPW": typed[i++],
  "EFEPKW": typed[i++],
  "EFEBRW": typed[i++]
} as const;

export { typed as PATCH_COLORS };
