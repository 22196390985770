// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { GloveAssetRow } from '../typings';

const data = [
  {
    "sportId": "softball",
    "id": "125SB",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3842,
          "height": 4861
        },
        "web": {
          "width": 3766,
          "height": 4832
        },
        "palm": {
          "width": 4094,
          "height": 4704
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnPcfp": false,
      "allowShellPalmColorForLining": true,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": false,
      "insertOnBackView": true,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": true,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "PatchEaston": true,
          "JShomeplate": false,
          "OwnThisField": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": false,
          "Professional": false,
          "RawlingsStamp": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": false,
          "JShomeplate": false
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false,
          "PatchEaston": false,
          "OwnThisField": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "715SB",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3752,
          "height": 4925
        },
        "web": {
          "width": 4064,
          "height": 5226
        },
        "palm": {
          "width": 1920,
          "height": 2327
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": true,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnPcfp": false,
      "allowShellPalmColorForLining": true,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": false,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": false,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": true,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "PatchEaston": true,
          "JShomeplate": false,
          "OwnThisField": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": false,
          "Professional": false,
          "RawlingsStamp": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": false,
          "JShomeplate": false
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false,
          "PatchEaston": false,
          "OwnThisField": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "125KR",
    "shapeId": "fielder",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3674,
          "height": 4866
        },
        "web": {
          "width": 2721,
          "height": 3000
        },
        "palm": {
          "width": 2515,
          "height": 3000
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnPcfp": false,
      "allowShellPalmColorForLining": true,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": true,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": false,
      "weltingOnBackView": true,
      "welting": true,
      "xrdPad": false,
      "hasFlag": true,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": true,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "deco3D": {
        "web": {
          "Flag": true,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "PatchEaston": true,
          "JShomeplate": false,
          "OwnThisField": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": false,
          "Professional": false,
          "RawlingsStamp": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": false,
          "JShomeplate": false
        },
        "back": {
          "Flag": true,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": true,
          "Fastback": false,
          "Oval": false,
          "PatchEaston": false,
          "OwnThisField": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "FM19SB",
    "shapeId": "firstBase",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2397,
          "height": 3224
        },
        "web": {
          "width": 2574,
          "height": 3398
        },
        "palm": {
          "width": 2650,
          "height": 3309
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnPcfp": false,
      "allowShellPalmColorForLining": true,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": false,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": false,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": true,
      "insertOnBackView": false,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "weltingOnBackView": false,
      "welting": true,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": true,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": false,
          "EmbroideryLogo": true,
          "GoldGlove": false,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": true,
          "MiddleFingerXRD": false,
          "PatchEaston": true,
          "JShomeplate": false,
          "OwnThisField": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": false,
          "Professional": false,
          "RawlingsStamp": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": false,
          "JShomeplate": false
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false,
          "PatchEaston": false,
          "OwnThisField": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "CM33FP",
    "shapeId": "catcher",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 3516,
          "height": 4845
        },
        "web": {
          "width": 4440,
          "height": 4807
        },
        "palm": {
          "width": 5049,
          "height": 5214
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnPcfp": true,
      "allowShellPalmColorForLining": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": true,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": false,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "weltingOnBackView": false,
      "welting": true,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": true,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "PatchEaston": true,
          "JShomeplate": false,
          "OwnThisField": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": false,
          "Professional": false,
          "RawlingsStamp": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": false,
          "JShomeplate": false
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": false,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false,
          "PatchEaston": false,
          "OwnThisField": false
        }
      }
    }
  },
  {
    "sportId": "softball",
    "id": "PCFPRUDI",
    "shapeId": "catcher",
    "props": {
      "imageDimensions": {
        "back": {
          "width": 2177,
          "height": 3000
        },
        "web": {
          "width": 2771,
          "height": 3000
        },
        "palm": {
          "width": 2905,
          "height": 3000
        }
      },
      "hasHandSewnStitching": false,
      "trimWithShellBack": false,
      "liningOnBackView": true,
      "liningOnPalmView": true,
      "useBackPalmColorForLiningOnPcfp": true,
      "allowShellPalmColorForLining": false,
      "logoPatchOnWebView": true,
      "logoPatchOnBackView": true,
      "MLBOnWebView": false,
      "MLBOnBackView": false,
      "MLBOnWebViewMask": false,
      "MLBOnBackViewMask": false,
      "extraStitching": false,
      "hasBackPalm": true,
      "holdsterLaces": false,
      "palmOverlay": false,
      "palmOnBackView": false,
      "palmOnWebView": true,
      "insertOnBackView": true,
      "insertOnWebView": true,
      "insertOnPalmView": true,
      "webOnBackView": true,
      "weltingOnBackView": false,
      "welting": true,
      "xrdPad": true,
      "hasFlag": false,
      "hasFlagOnHalfMesh": false,
      "uniqueFlagPlacement": false,
      "embroideryNameReplacesLogo": false,
      "pullStrapAboveOval": true,
      "hasStandardFitShadowOnWeb": true,
      "hasStandardFitShadowOnBack": true,
      "fingerPadIsRequired": false,
      "forceHalfHeel": false,
      "deco3D": {
        "web": {
          "Flag": false,
          "Wing": false,
          "Oval": true,
          "Name": true,
          "PalmPadLeather": true,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "Fastback": false,
          "TrapezeWeb": false,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "PatchEaston": true,
          "JShomeplate": true,
          "OwnThisField": false
        },
        "palm": {
          "LeatherType": true,
          "USSteerhide": false,
          "Professional": false,
          "RawlingsStamp": false,
          "ModelName": true,
          "LiTeToe": false,
          "R2G": false,
          "JShomeplate": false
        },
        "back": {
          "Flag": false,
          "Wing": false,
          "Name": false,
          "EmbroideryLogo": true,
          "GoldGlove": true,
          "IndexFingerXRD": false,
          "MiddleFingerXRD": false,
          "Fastback": false,
          "Oval": false,
          "PatchEaston": false,
          "OwnThisField": false
        }
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GloveAssetRow[]>> = data;

export type GloveAsset = typeof typed[number];

export const GLOVE_ASSET_INDEX_KEY = "id";
export type GloveAssetIndexKey = "id";
export type GloveAssetId = GloveAsset["id"];

let i = 0;
export const GLOVE_ASSET_DICT = {
  "125SB": typed[i++],
  "715SB": typed[i++],
  "125KR": typed[i++],
  "FM19SB": typed[i++],
  "CM33FP": typed[i++],
  "PCFPRUDI": typed[i++]
} as const;

export { typed as GLOVE_ASSETS };
