// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PositionRow } from '../typings';

const data = [
  {
    "id": "infield",
    "name": "Infield",
    "description": "Small to mid-size gloves specifically designed for infielders.",
    "props": {
      "abbr": "IF"
    }
  },
  {
    "id": "outfield",
    "name": "Outfield",
    "description": "Mid to large-size gloves specifically designed for outfielders.",
    "props": {
      "abbr": "OF"
    }
  },
  {
    "id": "pitcher",
    "name": "Pitcher",
    "description": "Midsized gloves specifically designed for pitchers. Consult your league rules regarding white and gray color options.",
    "props": {
      "abbr": "P"
    }
  },
  {
    "id": "firstBase",
    "name": "First Base",
    "description": "Mitts specifically designed for the first base position.",
    "props": {
      "abbr": "1B"
    }
  },
  {
    "id": "catcher",
    "name": "Catcher",
    "description": "Mitts specifically designed for catchers.",
    "props": {
      "abbr": "C"
    }
  },
  {
    "id": "training",
    "name": "Training",
    "description": "Gloves to help you improve your game. Develop quickness and responsiveness.",
    "props": {
      "abbr": "T"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PositionRow[]>> = data;

export type Position = typeof typed[number];

export const POSITION_INDEX_KEY = "id";
export type PositionIndexKey = "id";
export type PositionId = Position["id"];

let i = 0;
export const POSITION_DICT = {
  "infield": typed[i++],
  "outfield": typed[i++],
  "pitcher": typed[i++],
  "firstBase": typed[i++],
  "catcher": typed[i++],
  "training": typed[i++]
} as const;

export { typed as POSITIONS };
