// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { WebRow } from '../typings';

const data = [
  {
    "id": "SingleP",
    "name": "Single Post (1)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "ProI",
    "name": "Pro I (2)",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "Basket",
    "name": "Basket Web (3)",
    "asset": {
      "logo": false,
      "detail": true,
      "binding": false
    }
  },
  {
    "id": "ModTrapeze",
    "name": "Modified Trap-Eze (4)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "SinglePDB",
    "name": "Single Post Double Bar (5)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "ProH",
    "name": "Pro H (6)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "ProV",
    "name": "Pro V (7)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "OnePS",
    "name": "1-Piece Solid (8)",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "TwoPS",
    "name": "2-Piece Solid (9)",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "SinglePDBbase",
    "name": "Single Post Double Bar (10)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "BasketConnector",
    "name": "Basket Connector (11)",
    "asset": {
      "logo": false,
      "detail": true,
      "binding": false
    }
  },
  {
    "id": "VerticalH",
    "name": "Vertical Hinge (12)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "ModSinglePost",
    "name": "Modified Singe Post (13)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "VerticalHB",
    "name": "Vertical Hinge Basket Web (14)",
    "asset": {
      "logo": false,
      "detail": true,
      "binding": false
    }
  },
  {
    "id": "ModTrapezeLoop",
    "name": "Modified Trapeze Loop (15)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "Ypost",
    "name": "Y-Post (16)",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "ModProH",
    "name": "Modified Pro H (17)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "DoubleLacedBasket",
    "name": "Double Laced Basket (18)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "XLacedSingleP",
    "name": "X Laced Single Post (19)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "SinglePwXLace",
    "name": "Single Post w/ X Lace (20)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "HorizontalXLaces",
    "name": "Horizontal Bar X-Lace (21)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "Trapeze",
    "name": "Trapeze (22)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": true
    }
  },
  {
    "id": "OnePSCatcher",
    "name": "1-Piece Solid (23)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "ProHCatcher",
    "name": "Pro-H (24)",
    "asset": {
      "idOverride": "ProH",
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "TwoPSCatcher",
    "name": "2-Piece Solid (25)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "TwoPSJS",
    "name": "2-Piece Solid (25JS)",
    "asset": {
      "logo": false,
      "detail": false,
      "binding": false,
      "stampName": "JShomeplate"
    }
  },
  {
    "id": "Laced2PS",
    "name": "Laced 2-Piece Solid (30)",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false
    }
  },
  {
    "id": "Laced1PS",
    "name": "Laced 1-Piece Solid (31)",
    "asset": {
      "logo": true,
      "detail": false,
      "binding": false
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<WebRow[]>> = data;

export type Web = typeof typed[number];

export const WEB_INDEX_KEY = "id";
export type WebIndexKey = "id";
export type WebId = Web["id"];

let i = 0;
export const WEB_DICT = {
  "SingleP": typed[i++],
  "ProI": typed[i++],
  "Basket": typed[i++],
  "ModTrapeze": typed[i++],
  "SinglePDB": typed[i++],
  "ProH": typed[i++],
  "ProV": typed[i++],
  "OnePS": typed[i++],
  "TwoPS": typed[i++],
  "SinglePDBbase": typed[i++],
  "BasketConnector": typed[i++],
  "VerticalH": typed[i++],
  "ModSinglePost": typed[i++],
  "VerticalHB": typed[i++],
  "ModTrapezeLoop": typed[i++],
  "Ypost": typed[i++],
  "ModProH": typed[i++],
  "DoubleLacedBasket": typed[i++],
  "XLacedSingleP": typed[i++],
  "SinglePwXLace": typed[i++],
  "HorizontalXLaces": typed[i++],
  "Trapeze": typed[i++],
  "OnePSCatcher": typed[i++],
  "ProHCatcher": typed[i++],
  "TwoPSCatcher": typed[i++],
  "TwoPSJS": typed[i++],
  "Laced2PS": typed[i++],
  "Laced1PS": typed[i++]
} as const;

export { typed as WEBS };
