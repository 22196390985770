// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { NavTreeItemRow } from '../typings';

const data = [
  {
    "childId": "filter",
    "name": "Filters",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "filter"
  },
  {
    "parentId": "filter",
    "childId": "productSelector",
    "name": "Glove Finder",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": true,
    "id": "filter.productSelector"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "productSelector.sport",
    "name": "Sport",
    "propId": "filter.sport",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "wizardTitle": "What <strong>sport</strong> do you play?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.productSelector.sport"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "productSelector.leather",
    "name": "Leather",
    "propId": "product.leather",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "wizardTitle": "What <strong>leather</strong> would you prefer?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.productSelector.leather"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "productSelector.fit",
    "name": "Fit",
    "propId": "product.fit",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "wizardTitle": "What glove <strong>fit</strong> matches your hand?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.productSelector.fit"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "productSelector.position",
    "name": "Position",
    "propId": "filter.position",
    "isWizardStep": true,
    "isWizardStepOptional": true,
    "isNew": false,
    "wizardTitle": "What <strong>position</strong> do you play?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.productSelector.position"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "productSelector.size",
    "name": "Size",
    "propId": "filter.size",
    "isWizardStep": true,
    "isWizardStepOptional": true,
    "isNew": false,
    "wizardTitle": "What <strong>size</strong> glove do you wear?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.productSelector.size"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "productSelector.glove",
    "name": "Pattern",
    "propId": "product.glove",
    "isWizardStep": true,
    "isWizardStepOptional": false,
    "isNew": false,
    "wizardTitle": "Which <strong>pattern</strong> would you like?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.productSelector.glove"
  },
  {
    "childId": "product",
    "name": "Product",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "product"
  },
  {
    "parentId": "product",
    "childId": "throwingHand",
    "name": "Throwing Hand",
    "propId": "product.throwingHand",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "product.throwingHand"
  },
  {
    "childId": "colors",
    "name": "Colors",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors"
  },
  {
    "parentId": "colors",
    "childId": "web",
    "name": "Web",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.web"
  },
  {
    "parentId": "colors.web",
    "childId": "style",
    "name": "Style",
    "propId": "colors.web.style",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.web.style"
  },
  {
    "parentId": "colors.web",
    "childId": "color",
    "name": "Color",
    "propId": "colors.web.color",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.web.color"
  },
  {
    "parentId": "colors",
    "childId": "logoPatch",
    "name": "Logo",
    "propId": "colors.logoPatch",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.logoPatch"
  },
  {
    "parentId": "colors",
    "childId": "shellBack",
    "name": "Shell Back",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.shellBack"
  },
  {
    "parentId": "colors.shellBack",
    "childId": "design",
    "name": "Design",
    "propId": "colors.shellBack.design",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.shellBack.design"
  },
  {
    "parentId": "colors.shellBack",
    "childId": "material",
    "name": "Material",
    "propId": "colors.shellBack.material",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.shellBack.material"
  },
  {
    "parentId": "colors.shellBack",
    "childId": "leatherColor",
    "name": "Leather Color",
    "propId": "colors.shellBack.leatherColor",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.shellBack.leatherColor"
  },
  {
    "parentId": "colors",
    "childId": "shellPalm",
    "name": "Shell Palm",
    "propId": "colors.shellPalm",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.shellPalm"
  },
  {
    "parentId": "colors",
    "childId": "backPalm",
    "name": "Back Palm",
    "propId": "colors.backPalm",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.backPalm"
  },
  {
    "parentId": "colors",
    "childId": "laces",
    "name": "Laces",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.laces"
  },
  {
    "parentId": "colors.laces",
    "childId": "fingerWeb",
    "name": "Finger & Web",
    "propId": "colors.laces.fingerWeb",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.laces.fingerWeb"
  },
  {
    "parentId": "colors.laces",
    "childId": "heel",
    "name": "Heel",
    "propId": "colors.laces.heel",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.laces.heel"
  },
  {
    "parentId": "colors.laces",
    "childId": "laceLength",
    "name": "Length",
    "propId": "colors.laces.laceLength",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.laces.laceLength"
  },
  {
    "parentId": "colors",
    "childId": "lining",
    "name": "Lining",
    "propId": "colors.lining",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.lining"
  },
  {
    "parentId": "colors",
    "childId": "welting",
    "name": "Welting",
    "propId": "colors.welting.all",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.welting"
  },
  {
    "parentId": "colors",
    "childId": "weltingNested",
    "name": "Welting",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.weltingNested"
  },
  {
    "parentId": "colors.weltingNested",
    "childId": "palm",
    "name": "Palm",
    "propId": "colors.welting.palm",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.weltingNested.palm"
  },
  {
    "parentId": "colors.weltingNested",
    "childId": "back",
    "name": "Back",
    "propId": "colors.welting.back",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.weltingNested.back"
  },
  {
    "parentId": "colors.weltingNested",
    "childId": "handSewn",
    "name": "Hand Sewn",
    "propId": "colors.welting.handSewn",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.weltingNested.handSewn"
  },
  {
    "parentId": "colors.weltingNested",
    "childId": "handSewnStitching",
    "name": "Stitching",
    "propId": "colors.welting.handSewnStitching",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.weltingNested.handSewnStitching"
  },
  {
    "parentId": "colors",
    "childId": "binding",
    "name": "Binding",
    "propId": "colors.binding",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.binding"
  },
  {
    "parentId": "colors",
    "childId": "trim",
    "name": "Trim",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.trim"
  },
  {
    "parentId": "colors.trim",
    "childId": "style",
    "name": "Style",
    "propId": "colors.trim.style",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.trim.style"
  },
  {
    "parentId": "colors.trim",
    "childId": "color",
    "name": "Color",
    "propId": "colors.trim.color",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.trim.color"
  },
  {
    "parentId": "colors",
    "childId": "stitching",
    "name": "Stitching",
    "propId": "colors.stitching",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.stitching"
  },
  {
    "parentId": "colors",
    "childId": "stamping",
    "name": "Stamping",
    "propId": "colors.stamping",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "colors.stamping"
  },
  {
    "childId": "options",
    "name": "Options",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "options"
  },
  {
    "parentId": "options",
    "childId": "fingerPadHood",
    "name": "Finger Pad/Hood",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "options.fingerPadHood"
  },
  {
    "parentId": "options.fingerPadHood",
    "childId": "fingerPad",
    "name": "Finger Pad",
    "propId": "options.fingerPadHood.fingerPad",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "options.fingerPadHood.fingerPad"
  },
  {
    "parentId": "options.fingerPadHood",
    "childId": "fingerHood",
    "name": "Finger Hood",
    "propId": "options.fingerPadHood.fingerHood",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "options.fingerPadHood.fingerHood"
  },
  {
    "parentId": "options.fingerPadHood",
    "childId": "position",
    "name": "Position",
    "propId": "options.fingerPadHood.position",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "options.fingerPadHood.position"
  },
  {
    "parentId": "options",
    "childId": "palmPad",
    "name": "Palm Pad",
    "propId": "options.palmPad",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "options.palmPad"
  },
  {
    "parentId": "options",
    "childId": "heelPad",
    "name": "Heel Pad",
    "propId": "options.heelPad",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "options.heelPad"
  },
  {
    "parentId": "options",
    "childId": "wristLining",
    "name": "Wrist Liner",
    "propId": "options.wristLining",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "options.wristLining"
  },
  {
    "parentId": "options",
    "childId": "breakIn",
    "name": "Break-In",
    "propId": "options.breakIn",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "options.breakIn"
  },
  {
    "childId": "personalization",
    "name": "Personalization",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "personalization"
  },
  {
    "parentId": "personalization",
    "childId": "embroidery",
    "name": "Embroidery",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "personalization.embroidery"
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "color",
    "name": "Color",
    "propId": "personalization.embroidery.color",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "personalization.embroidery.color"
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "font",
    "name": "Font",
    "propId": "personalization.embroidery.number.font",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "personalization.embroidery.font"
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "number",
    "name": "Number",
    "propId": "personalization.embroidery.number.text",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "personalization.embroidery.number"
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "logo",
    "name": "Logo",
    "propId": "personalization.embroidery.logo",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "personalization.embroidery.logo"
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "thumb",
    "name": "Thumb",
    "propId": "personalization.embroidery.thumb.text",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "personalization.embroidery.thumb"
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "pinky",
    "name": "Pinky",
    "propId": "personalization.embroidery.pinky.text",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "isNew": false,
    "shouldShowChildren": false,
    "id": "personalization.embroidery.pinky"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<NavTreeItemRow[]>> = data;

export type NavTreeItem = typeof typed[number];

export const NAV_TREE_ITEM_INDEX_KEY = "id";
export type NavTreeItemIndexKey = "id";
export type NavTreeItemId = NavTreeItem["id"];

let i = 0;
export const NAV_TREE_ITEM_DICT = {
  "filter": typed[i++],
  "filter.productSelector": typed[i++],
  "filter.productSelector.productSelector.sport": typed[i++],
  "filter.productSelector.productSelector.leather": typed[i++],
  "filter.productSelector.productSelector.fit": typed[i++],
  "filter.productSelector.productSelector.position": typed[i++],
  "filter.productSelector.productSelector.size": typed[i++],
  "filter.productSelector.productSelector.glove": typed[i++],
  "product": typed[i++],
  "product.throwingHand": typed[i++],
  "colors": typed[i++],
  "colors.web": typed[i++],
  "colors.web.style": typed[i++],
  "colors.web.color": typed[i++],
  "colors.logoPatch": typed[i++],
  "colors.shellBack": typed[i++],
  "colors.shellBack.design": typed[i++],
  "colors.shellBack.material": typed[i++],
  "colors.shellBack.leatherColor": typed[i++],
  "colors.shellPalm": typed[i++],
  "colors.backPalm": typed[i++],
  "colors.laces": typed[i++],
  "colors.laces.fingerWeb": typed[i++],
  "colors.laces.heel": typed[i++],
  "colors.laces.laceLength": typed[i++],
  "colors.lining": typed[i++],
  "colors.welting": typed[i++],
  "colors.weltingNested": typed[i++],
  "colors.weltingNested.palm": typed[i++],
  "colors.weltingNested.back": typed[i++],
  "colors.weltingNested.handSewn": typed[i++],
  "colors.weltingNested.handSewnStitching": typed[i++],
  "colors.binding": typed[i++],
  "colors.trim": typed[i++],
  "colors.trim.style": typed[i++],
  "colors.trim.color": typed[i++],
  "colors.stitching": typed[i++],
  "colors.stamping": typed[i++],
  "options": typed[i++],
  "options.fingerPadHood": typed[i++],
  "options.fingerPadHood.fingerPad": typed[i++],
  "options.fingerPadHood.fingerHood": typed[i++],
  "options.fingerPadHood.position": typed[i++],
  "options.palmPad": typed[i++],
  "options.heelPad": typed[i++],
  "options.wristLining": typed[i++],
  "options.breakIn": typed[i++],
  "personalization": typed[i++],
  "personalization.embroidery": typed[i++],
  "personalization.embroidery.color": typed[i++],
  "personalization.embroidery.font": typed[i++],
  "personalization.embroidery.number": typed[i++],
  "personalization.embroidery.logo": typed[i++],
  "personalization.embroidery.thumb": typed[i++],
  "personalization.embroidery.pinky": typed[i++]
} as const;

export { typed as NAV_TREE };
