import * as _ from '@technically/lodash'
import wildcard from 'wildcard'

const MENU_MATCHER_TO_CAMERA_CAMERA_ID = [
  ['colors.web.*', 'web_cam'],
  ['options.palmPad', 'palm_cam'],
  ['options.shellPad', 'palm_cam'],
  ['colors.logoPatch', 'patch_cam'],
  ['colors.shellBack.*', 'shellBack_cam'],
]

const getCameraFromMenu = (menu?: string) => {
  if (!menu) {
    return
  }

  const match = _.find(MENU_MATCHER_TO_CAMERA_CAMERA_ID, ([matcher]) =>
    wildcard(matcher, menu),
  ) as [string, string] | undefined

  if (!match) {
    return
  }

  const [, camera] = match

  return camera
}

export default getCameraFromMenu
