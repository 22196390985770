// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { LeatherRow } from '../typings';

const data = [
  {
    "id": "pcfp",
    "name": "Professional Collection",
    "description": "Constructed from Rawlings world-renowned Heart of the Hide steer hide leather, Heart of the Hide gloves feature the game-day patterns of the top Rawlings Advisory Staff players. These high quality gloves have defined the careers of those deemed \"The Finest in the Field.\"",
    "props": {
      "sku": "PCFP",
      "forceFullHeel": false
    },
    "limitations": {
      "weltingTypes": {
        "all": false,
        "palm": true,
        "back": true
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<LeatherRow[]>> = data;

export type Leather = typeof typed[number];

export const LEATHER_INDEX_KEY = "id";
export type LeatherIndexKey = "id";
export type LeatherId = Leather["id"];

let i = 0;
export const LEATHER_DICT = {
  "pcfp": typed[i++]
} as const;

export { typed as LEATHERS };
