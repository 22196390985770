import { map } from '@technically/lodash'
import React from 'react'
import cn from 'classnames'

import RenderComposite from '~p/client/renderComposite/containers/RenderComposite'

import RawlingsRenderer from '~rawlings/client/components/Renderer'
import Spinner from '~rawlings/client/components/Spinner'
import Icon from '~rawlings/client/components/Icon'
import ViewSwitcher from '~rawlings/client/components/ViewSwitcher'

import '~rawlings/client/components/Renderer.css'

import controlTree from '../controlTree'
import viewAngles from '../../viewAngles'
import * as renderComposite from '../../renderComposite'

const Renderer = (props) => {
  const isScrollMenuLayout = props.layoutMode !== 'mobile' && !props.isFocusMode

  return (
    <div>
      {!props.isFocusMode && (
        <div
          className={cn(
            'view-icons',
            isScrollMenuLayout && 'mod-is-scroll-menu-layout',
          )}
        >
          {map(viewAngles, ({ label }, view) => (
            <div
              key={view}
              className={cn('view-icon', {
                'is-active': props.activeView === view,
              })}
              onClick={() => {
                props.setView(view)
              }}
            >
              <Icon name={view} />
              <div className="label">{label}</div>
            </div>
          ))}
          <div
            className="view-icon magnifier"
            onClick={() => {
              props.setFocusMode(true)
            }}
          >
            <Icon name="magnifier" />
            <div className="label">Zoom In</div>
          </div>
        </div>
      )}
      <div
        className={cn('preview', {
          'is-focus': props.isFocusMode,
          'has-more-than-one': true,
          'has-gaps': true,
        })}
      >
        <ViewSwitcher
          viewAngles={viewAngles}
          activeView={props.activeView}
          setView={props.setView}
          setFocusMode={props.setFocusMode}
          isFocusMode={props.isFocusMode}
          isScrollMenuLayout={isScrollMenuLayout}
        >
          <RawlingsRenderer
            isLoading={false}
            isFocusMode={props.isFocusMode}
            isScrollMenuLayout={isScrollMenuLayout}
          >
            <RenderComposite
              controlTree={controlTree}
              viewAngles={viewAngles}
              renderComposite={renderComposite}
              LoadingIndicator={Spinner}
            />
          </RawlingsRenderer>
        </ViewSwitcher>
      </div>
    </div>
  )
}

export default Renderer
