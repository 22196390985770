import allAssetsWithNumber from './allAssetsWithNumber'
import allGloves from './allGloves'
import allGlovesWithLogos from './allGlovesWithLogos'
import allNumbers from './allNumbers'
import allWebs from './allWebs'
import allWebsMetallic from './allWebsMetallic'

export default {
  allAssetsWithNumber,
  allGloves,
  allGlovesWithLogos,
  allNumbers,
  allWebs,
  allWebsMetallic,
}
