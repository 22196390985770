// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { BreakInRow } from '../typings';

const data = [
  {
    "id": "soft",
    "name": "Soft",
    "description": "50% factory / 50% player",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  },
  {
    "id": "standard",
    "name": "Standard",
    "description": "30% factory / 70% player",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  },
  {
    "id": "firm",
    "name": "Firm",
    "description": "10% factory / 90% player",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<BreakInRow[]>> = data;

export type BreakIn = typeof typed[number];

export const BREAK_IN_INDEX_KEY = "id";
export type BreakInIndexKey = "id";
export type BreakInId = BreakIn["id"];

let i = 0;
export const BREAK_IN_DICT = {
  "soft": typed[i++],
  "standard": typed[i++],
  "firm": typed[i++]
} as const;

export { typed as BREAK_INS };
