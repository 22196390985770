// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PropDefRow } from '../typings';

const data = [
  {
    "name": "Sport",
    "id": "filter_sport",
    "dataType": "sport",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "web"
    ]
  },
  {
    "name": "Leather",
    "id": "product_leather",
    "dataType": "leather",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "leather"
  },
  {
    "name": "Fit",
    "id": "product_fit",
    "dataType": "fit",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "fit-type"
  },
  {
    "name": "Position",
    "id": "filter_position",
    "dataType": "position",
    "tileType": "square",
    "tileImageProps": "icons/position/${id}.png",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "name": "Size",
    "id": "filter_size",
    "dataType": "size",
    "tileType": "squareWithText",
    "isOptional": true,
    "viewNames": [
      "web"
    ]
  },
  {
    "name": "Glove",
    "id": "product_glove",
    "description": "web options available after choosing a glove",
    "dataType": "glove",
    "tileType": "square",
    "tileImageProps": "icons/model/${id}.png",
    "isOptional": true,
    "viewNames": [
      "web"
    ],
    "legacyPath": "design-template"
  },
  {
    "name": "Throwing Hand",
    "id": "product_throwingHand",
    "description": "not visualized",
    "dataType": "hand",
    "tileType": "squareWithText",
    "defaultValueId": "right",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "throwing-hand"
  },
  {
    "name": "Web Style",
    "id": "colors_web_style",
    "dataType": "web",
    "tileType": "square",
    "tileImageProps": "icons/web/${id}.jpg",
    "isOptional": false,
    "viewNames": [
      "web",
      "palm"
    ],
    "legacyPath": "web-style"
  },
  {
    "name": "Web Color",
    "id": "colors_web_color",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "white",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "web-color"
  },
  {
    "name": "Logo Color",
    "id": "colors_logoPatch",
    "dataType": "patchColor",
    "tileType": "square",
    "tileImageProps": "icons/logoPatch/a${asset.patchAssetId}.png",
    "defaultValueId": "EFEMW",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "logo-patch-color"
  },
  {
    "name": "Shell Back Design",
    "id": "colors_shellBack_design",
    "dataType": "design",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "shell-back-design"
  },
  {
    "name": "Shell Back Material",
    "id": "colors_shellBack_material",
    "dataType": "material",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "shell-back-material"
  },
  {
    "name": "Shell Back Leather Color",
    "id": "colors_shellBack_leatherColor",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "white",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "shell-back-color"
  },
  {
    "name": "Shell Palm Color",
    "id": "colors_shellPalm",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "white",
    "isOptional": false,
    "viewNames": [
      "palm"
    ],
    "legacyPath": "shell-palm-color"
  },
  {
    "name": "Back Palm Color",
    "id": "colors_backPalm",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "white",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "back-palm-color"
  },
  {
    "name": "Finger & Web Laces Color",
    "id": "colors_laces_fingerWeb",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "oceanMint",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "finger-and-web-laces-color"
  },
  {
    "name": "Heel Laces Color",
    "id": "colors_laces_heel",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "oceanMint",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "heel-laces-color"
  },
  {
    "name": "Lace Length",
    "id": "colors_laces_laceLength",
    "description": "not visualized",
    "dataType": "laceLength",
    "tileType": "squareWithText",
    "defaultValueId": "standard",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "lace-length"
  },
  {
    "name": "Lining Color",
    "id": "colors_lining",
    "dataType": "leatherColor",
    "tileType": "squareWithText",
    "defaultValueId": "black",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "lining-color"
  },
  {
    "name": "All Welting Color",
    "id": "colors_welting_all",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "black",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "welting-color"
  },
  {
    "name": "Palm Welting Color",
    "id": "colors_welting_palm",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "oceanMint",
    "isOptional": false,
    "viewNames": [
      "palm"
    ],
    "legacyPath": "palm-welting-color"
  },
  {
    "name": "Back Welting Color",
    "id": "colors_welting_back",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "oceanMint",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "back-welting-color"
  },
  {
    "name": "Hand Sewn",
    "id": "colors_welting_handSewn",
    "dataType": "toggles",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "hand-sewn"
  },
  {
    "name": "Hand Sewn Stitching",
    "id": "colors_welting_handSewnStitching",
    "dataType": "leatherColor",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "hand-sewn-color"
  },
  {
    "name": "Binding Color",
    "id": "colors_binding",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "oceanMint",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "binding-color"
  },
  {
    "name": "Trim Style",
    "id": "colors_trim_style",
    "dataType": "trim",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "trim-style"
  },
  {
    "name": "Trim Color",
    "id": "colors_trim_color",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "white",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "trim-color"
  },
  {
    "name": "Stitching Color",
    "id": "colors_stitching",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "mint",
    "isOptional": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "thread-color"
  },
  {
    "name": "Stamping Color",
    "id": "colors_stamping",
    "dataType": "leatherColor",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "palm"
    ],
    "legacyPath": "stamping-color"
  },
  {
    "name": "Finger Pad",
    "id": "options_fingerPadHood_fingerPad",
    "dataType": "fingerColor",
    "tileType": "squareWithText",
    "isOptional": true,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "finger-pad"
  },
  {
    "name": "Finger Hood",
    "id": "options_fingerPadHood_fingerHood",
    "dataType": "toggles",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "finger-hood"
  },
  {
    "name": "Finger Position",
    "id": "options_fingerPadHood_position",
    "dataType": "fingerPosition",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "finger-pad-or-hood-position"
  },
  {
    "name": "Palm Pad",
    "id": "options_palmPad",
    "dataType": "palmPad",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "palm-pad"
  },
  {
    "name": "Heel Pad",
    "id": "options_heelPad",
    "dataType": "heelPad",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "heel-pad"
  },
  {
    "name": "Wrist Liner",
    "id": "options_wristLining",
    "description": "lining across the top of the wrist opening",
    "dataType": "wristLining",
    "tileType": "squareWithText",
    "defaultValueId": "default",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "wrist-lining"
  },
  {
    "name": "Break-In",
    "id": "options_breakIn",
    "dataType": "breakIn",
    "tileType": "squareWithText",
    "defaultValueId": "standard",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "break-in"
  },
  {
    "name": "Embroidery Color",
    "id": "personalization_embroidery_color",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "mint",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "embroidery.color"
  },
  {
    "name": "Number Text",
    "id": "personalization_embroidery_number_text",
    "dataType": "text",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "embroidery.number-text"
  },
  {
    "name": "Embroidery Font",
    "id": "personalization_embroidery_number_font",
    "dataType": "font",
    "tileType": "square",
    "tileImageProps": "icons/font/${id}.jpg",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "embroidery.number-font"
  },
  {
    "name": "Logo",
    "id": "personalization_embroidery_logo",
    "dataType": "logo",
    "tileType": "square",
    "tileImageProps": "icons/logo/${id}.png",
    "isOptional": true,
    "viewNames": [
      "web"
    ],
    "legacyPath": "embroidery.logo"
  },
  {
    "name": "Thumb Text",
    "id": "personalization_embroidery_thumb_text",
    "dataType": "text",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "embroidery.thumb-text"
  },
  {
    "name": "Thumb Font",
    "id": "personalization_embroidery_thumb_font",
    "dataType": "font",
    "tileType": "square",
    "tileImageProps": "icons/font/${id}.jpg",
    "isOptional": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "embroidery.thumb-font"
  },
  {
    "name": "Pinky Text",
    "id": "personalization_embroidery_pinky_text",
    "dataType": "text",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back"
    ],
    "legacyPath": "embroidery.pinky-text"
  },
  {
    "name": "Pinky Font",
    "id": "personalization_embroidery_pinky_font",
    "dataType": "font",
    "tileType": "square",
    "tileImageProps": "icons/font/${id}.jpg",
    "isOptional": false,
    "viewNames": [
      "back"
    ],
    "legacyPath": "embroidery.pinky-font"
  },
  {
    "name": "Calc Sport",
    "id": "calc_sport",
    "dataType": "sport",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "sport"
  },
  {
    "name": "Calc Position",
    "id": "calc_position",
    "dataType": "position",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "position"
  },
  {
    "name": "Calc Size",
    "id": "calc_size",
    "dataType": "size",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "size"
  },
  {
    "name": "Calc SKU",
    "id": "calc_sku",
    "dataType": "sku",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ]
  },
  {
    "name": "Legacy SKU",
    "id": "legacy_sku",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "SKU"
  },
  {
    "name": "Legacy Shell Back ID",
    "id": "legacy_shellBackId",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "shell-back"
  },
  {
    "name": "Legacy Mesh Style",
    "id": "legacy_meshStyle",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "mesh-style"
  },
  {
    "name": "Legacy Index Finger Pad",
    "id": "legacy_indexFingerPad",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "index-finger-pad"
  },
  {
    "name": "Legacy Index Finger Hood",
    "id": "legacy_indexFingerHood",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "index-finger-hood"
  },
  {
    "name": "Legacy Middle Finger Pad",
    "id": "legacy_middleFingerPad",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "middle-finger-pad"
  },
  {
    "name": "Legacy Middle Finger Hood",
    "id": "legacy_middleFingerHood",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "middle-finger-hood"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PropDefRow[]>> = data;

export type PropDef = typeof typed[number];

export const PROP_DEF_INDEX_KEY = "id";
export type PropDefIndexKey = "id";
export type PropDefId = PropDef["id"];

let i = 0;
export const PROP_DEF_DICT = {
  "filter_sport": typed[i++],
  "product_leather": typed[i++],
  "product_fit": typed[i++],
  "filter_position": typed[i++],
  "filter_size": typed[i++],
  "product_glove": typed[i++],
  "product_throwingHand": typed[i++],
  "colors_web_style": typed[i++],
  "colors_web_color": typed[i++],
  "colors_logoPatch": typed[i++],
  "colors_shellBack_design": typed[i++],
  "colors_shellBack_material": typed[i++],
  "colors_shellBack_leatherColor": typed[i++],
  "colors_shellPalm": typed[i++],
  "colors_backPalm": typed[i++],
  "colors_laces_fingerWeb": typed[i++],
  "colors_laces_heel": typed[i++],
  "colors_laces_laceLength": typed[i++],
  "colors_lining": typed[i++],
  "colors_welting_all": typed[i++],
  "colors_welting_palm": typed[i++],
  "colors_welting_back": typed[i++],
  "colors_welting_handSewn": typed[i++],
  "colors_welting_handSewnStitching": typed[i++],
  "colors_binding": typed[i++],
  "colors_trim_style": typed[i++],
  "colors_trim_color": typed[i++],
  "colors_stitching": typed[i++],
  "colors_stamping": typed[i++],
  "options_fingerPadHood_fingerPad": typed[i++],
  "options_fingerPadHood_fingerHood": typed[i++],
  "options_fingerPadHood_position": typed[i++],
  "options_palmPad": typed[i++],
  "options_heelPad": typed[i++],
  "options_wristLining": typed[i++],
  "options_breakIn": typed[i++],
  "personalization_embroidery_color": typed[i++],
  "personalization_embroidery_number_text": typed[i++],
  "personalization_embroidery_number_font": typed[i++],
  "personalization_embroidery_logo": typed[i++],
  "personalization_embroidery_thumb_text": typed[i++],
  "personalization_embroidery_thumb_font": typed[i++],
  "personalization_embroidery_pinky_text": typed[i++],
  "personalization_embroidery_pinky_font": typed[i++],
  "calc_sport": typed[i++],
  "calc_position": typed[i++],
  "calc_size": typed[i++],
  "calc_sku": typed[i++],
  "legacy_sku": typed[i++],
  "legacy_shellBackId": typed[i++],
  "legacy_meshStyle": typed[i++],
  "legacy_indexFingerPad": typed[i++],
  "legacy_indexFingerHood": typed[i++],
  "legacy_middleFingerPad": typed[i++],
  "legacy_middleFingerHood": typed[i++]
} as const;

export { typed as PROP_DEFS };
