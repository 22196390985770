// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SummaryTreeItemRow } from '../typings';

const data = [
  {
    "childId": "product",
    "name": "Product",
    "id": "product"
  },
  {
    "parentId": "product",
    "childId": "glove",
    "name": "Glove",
    "propId": "product.glove",
    "id": "product.glove"
  },
  {
    "parentId": "product",
    "childId": "sport",
    "name": "Sport",
    "propId": "calc.sport",
    "id": "product.sport"
  },
  {
    "parentId": "product",
    "childId": "leather",
    "name": "Leather",
    "propId": "product.leather",
    "id": "product.leather"
  },
  {
    "parentId": "product",
    "childId": "fit",
    "name": "Fit",
    "propId": "product.fit",
    "id": "product.fit"
  },
  {
    "parentId": "product",
    "childId": "throwingHand",
    "name": "Throwing Hand",
    "propId": "product.throwingHand",
    "id": "product.throwingHand"
  },
  {
    "parentId": "product",
    "childId": "position",
    "name": "Position",
    "propId": "calc.position",
    "id": "product.position"
  },
  {
    "parentId": "product",
    "childId": "size",
    "name": "Size",
    "propId": "calc.size",
    "id": "product.size"
  },
  {
    "childId": "colors",
    "name": "Colors",
    "id": "colors"
  },
  {
    "parentId": "colors",
    "childId": "web",
    "name": "Web",
    "id": "colors.web"
  },
  {
    "parentId": "colors.web",
    "childId": "style",
    "name": "Style",
    "propId": "colors.web.style",
    "id": "colors.web.style"
  },
  {
    "parentId": "colors.web",
    "childId": "color",
    "name": "Color",
    "propId": "colors.web.color",
    "id": "colors.web.color"
  },
  {
    "parentId": "colors",
    "childId": "logoPatch",
    "name": "Logo",
    "propId": "colors.logoPatch",
    "id": "colors.logoPatch"
  },
  {
    "parentId": "colors",
    "childId": "shellBack",
    "name": "Shell Back",
    "id": "colors.shellBack"
  },
  {
    "parentId": "colors.shellBack",
    "childId": "design",
    "name": "Design",
    "propId": "colors.shellBack.design",
    "id": "colors.shellBack.design"
  },
  {
    "parentId": "colors.shellBack",
    "childId": "material",
    "name": "Material",
    "propId": "colors.shellBack.material",
    "id": "colors.shellBack.material"
  },
  {
    "parentId": "colors.shellBack",
    "childId": "leatherColor",
    "name": "Leather",
    "propId": "colors.shellBack.leatherColor",
    "id": "colors.shellBack.leatherColor"
  },
  {
    "parentId": "colors",
    "childId": "shellPalm",
    "name": "Shell Palm",
    "propId": "colors.shellPalm",
    "id": "colors.shellPalm"
  },
  {
    "parentId": "colors",
    "childId": "backPalm",
    "name": "Back Palm",
    "propId": "colors.backPalm",
    "id": "colors.backPalm"
  },
  {
    "parentId": "colors",
    "childId": "laces",
    "name": "Laces",
    "id": "colors.laces"
  },
  {
    "parentId": "colors.laces",
    "childId": "fingerWeb",
    "name": "Finger & Web",
    "propId": "colors.laces.fingerWeb",
    "id": "colors.laces.fingerWeb"
  },
  {
    "parentId": "colors.laces",
    "childId": "heel",
    "name": "Heel",
    "propId": "colors.laces.heel",
    "id": "colors.laces.heel"
  },
  {
    "parentId": "colors.laces",
    "childId": "laceLength",
    "name": "Length",
    "propId": "colors.laces.laceLength",
    "id": "colors.laces.laceLength"
  },
  {
    "parentId": "colors",
    "childId": "lining",
    "name": "Lining",
    "propId": "colors.lining",
    "id": "colors.lining"
  },
  {
    "parentId": "colors",
    "childId": "welting",
    "name": "Welting",
    "propId": "colors.welting.all",
    "id": "colors.welting"
  },
  {
    "parentId": "colors",
    "childId": "weltingNested",
    "name": "Welting",
    "id": "colors.weltingNested"
  },
  {
    "parentId": "colors",
    "childId": "handSewn",
    "name": "Hand Sewn",
    "propId": "colors.welting.handSewn",
    "id": "colors.handSewn"
  },
  {
    "parentId": "colors",
    "childId": "handSewnStitching",
    "name": "Hand Sewn Stitching",
    "propId": "colors.welting.handSewnStitching",
    "id": "colors.handSewnStitching"
  },
  {
    "parentId": "colors.weltingNested",
    "childId": "palm",
    "name": "Palm",
    "propId": "colors.welting.palm",
    "id": "colors.weltingNested.palm"
  },
  {
    "parentId": "colors.weltingNested",
    "childId": "back",
    "name": "Back",
    "propId": "colors.welting.back",
    "id": "colors.weltingNested.back"
  },
  {
    "parentId": "colors",
    "childId": "binding",
    "name": "Binding",
    "propId": "colors.binding",
    "id": "colors.binding"
  },
  {
    "parentId": "colors",
    "childId": "trim",
    "name": "Trim",
    "id": "colors.trim"
  },
  {
    "parentId": "colors.trim",
    "childId": "style",
    "name": "Style",
    "propId": "colors.trim.style",
    "id": "colors.trim.style"
  },
  {
    "parentId": "colors.trim",
    "childId": "color",
    "name": "Color",
    "propId": "colors.trim.color",
    "id": "colors.trim.color"
  },
  {
    "parentId": "colors",
    "childId": "stitching",
    "name": "Stitching",
    "propId": "colors.stitching",
    "id": "colors.stitching"
  },
  {
    "parentId": "colors",
    "childId": "stamping",
    "name": "Stamping",
    "propId": "colors.stamping",
    "id": "colors.stamping"
  },
  {
    "childId": "options",
    "name": "Options",
    "id": "options"
  },
  {
    "parentId": "options",
    "childId": "fingerPadHood",
    "name": "Finger Pad/Hood",
    "id": "options.fingerPadHood"
  },
  {
    "parentId": "options.fingerPadHood",
    "childId": "fingerPad",
    "name": "Finger Pad",
    "propId": "options.fingerPadHood.fingerPad",
    "id": "options.fingerPadHood.fingerPad"
  },
  {
    "parentId": "options.fingerPadHood",
    "childId": "fingerHood",
    "name": "Finger Hood",
    "propId": "options.fingerPadHood.fingerHood",
    "id": "options.fingerPadHood.fingerHood"
  },
  {
    "parentId": "options.fingerPadHood",
    "childId": "position",
    "name": "Position",
    "propId": "options.fingerPadHood.position",
    "id": "options.fingerPadHood.position"
  },
  {
    "parentId": "options",
    "childId": "palmPad",
    "name": "Palm Pad",
    "propId": "options.palmPad",
    "id": "options.palmPad"
  },
  {
    "parentId": "options",
    "childId": "heelPad",
    "name": "Heel Pad",
    "propId": "options.heelPad",
    "id": "options.heelPad"
  },
  {
    "parentId": "options",
    "childId": "wristLining",
    "name": "Wrist Lining",
    "propId": "options.wristLining",
    "id": "options.wristLining"
  },
  {
    "parentId": "options",
    "childId": "breakIn",
    "name": "Break-In",
    "propId": "options.breakIn",
    "id": "options.breakIn"
  },
  {
    "childId": "personalization",
    "name": "Personalization",
    "id": "personalization"
  },
  {
    "parentId": "personalization",
    "childId": "embroidery",
    "name": "Embroidery",
    "id": "personalization.embroidery"
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "color",
    "name": "Color",
    "propId": "personalization.embroidery.color",
    "id": "personalization.embroidery.color"
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "number",
    "name": "Number",
    "id": "personalization.embroidery.number"
  },
  {
    "parentId": "personalization.embroidery.number",
    "childId": "text",
    "name": "Text",
    "propId": "personalization.embroidery.number.text",
    "id": "personalization.embroidery.number.text"
  },
  {
    "parentId": "personalization.embroidery.number",
    "childId": "font",
    "name": "Font",
    "propId": "personalization.embroidery.number.font",
    "id": "personalization.embroidery.number.font"
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "logo",
    "name": "Logo",
    "propId": "personalization.embroidery.logo",
    "id": "personalization.embroidery.logo"
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "thumb",
    "name": "Thumb",
    "id": "personalization.embroidery.thumb"
  },
  {
    "parentId": "personalization.embroidery.thumb",
    "childId": "text",
    "name": "Text",
    "propId": "personalization.embroidery.thumb.text",
    "id": "personalization.embroidery.thumb.text"
  },
  {
    "parentId": "personalization.embroidery.thumb",
    "childId": "font",
    "name": "Font",
    "propId": "personalization.embroidery.thumb.font",
    "id": "personalization.embroidery.thumb.font"
  },
  {
    "parentId": "personalization.embroidery",
    "childId": "pinky",
    "name": "Pinky",
    "id": "personalization.embroidery.pinky"
  },
  {
    "parentId": "personalization.embroidery.pinky",
    "childId": "text",
    "name": "Text",
    "propId": "personalization.embroidery.pinky.text",
    "id": "personalization.embroidery.pinky.text"
  },
  {
    "parentId": "personalization.embroidery.pinky",
    "childId": "font",
    "name": "Font",
    "propId": "personalization.embroidery.pinky.font",
    "id": "personalization.embroidery.pinky.font"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SummaryTreeItemRow[]>> = data;

export type SummaryTreeItem = typeof typed[number];

export const SUMMARY_TREE_ITEM_INDEX_KEY = "id";
export type SummaryTreeItemIndexKey = "id";
export type SummaryTreeItemId = SummaryTreeItem["id"];

let i = 0;
export const SUMMARY_TREE_ITEM_DICT = {
  "product": typed[i++],
  "product.glove": typed[i++],
  "product.sport": typed[i++],
  "product.leather": typed[i++],
  "product.fit": typed[i++],
  "product.throwingHand": typed[i++],
  "product.position": typed[i++],
  "product.size": typed[i++],
  "colors": typed[i++],
  "colors.web": typed[i++],
  "colors.web.style": typed[i++],
  "colors.web.color": typed[i++],
  "colors.logoPatch": typed[i++],
  "colors.shellBack": typed[i++],
  "colors.shellBack.design": typed[i++],
  "colors.shellBack.material": typed[i++],
  "colors.shellBack.leatherColor": typed[i++],
  "colors.shellPalm": typed[i++],
  "colors.backPalm": typed[i++],
  "colors.laces": typed[i++],
  "colors.laces.fingerWeb": typed[i++],
  "colors.laces.heel": typed[i++],
  "colors.laces.laceLength": typed[i++],
  "colors.lining": typed[i++],
  "colors.welting": typed[i++],
  "colors.weltingNested": typed[i++],
  "colors.handSewn": typed[i++],
  "colors.handSewnStitching": typed[i++],
  "colors.weltingNested.palm": typed[i++],
  "colors.weltingNested.back": typed[i++],
  "colors.binding": typed[i++],
  "colors.trim": typed[i++],
  "colors.trim.style": typed[i++],
  "colors.trim.color": typed[i++],
  "colors.stitching": typed[i++],
  "colors.stamping": typed[i++],
  "options": typed[i++],
  "options.fingerPadHood": typed[i++],
  "options.fingerPadHood.fingerPad": typed[i++],
  "options.fingerPadHood.fingerHood": typed[i++],
  "options.fingerPadHood.position": typed[i++],
  "options.palmPad": typed[i++],
  "options.heelPad": typed[i++],
  "options.wristLining": typed[i++],
  "options.breakIn": typed[i++],
  "personalization": typed[i++],
  "personalization.embroidery": typed[i++],
  "personalization.embroidery.color": typed[i++],
  "personalization.embroidery.number": typed[i++],
  "personalization.embroidery.number.text": typed[i++],
  "personalization.embroidery.number.font": typed[i++],
  "personalization.embroidery.logo": typed[i++],
  "personalization.embroidery.thumb": typed[i++],
  "personalization.embroidery.thumb.text": typed[i++],
  "personalization.embroidery.thumb.font": typed[i++],
  "personalization.embroidery.pinky": typed[i++],
  "personalization.embroidery.pinky.text": typed[i++],
  "personalization.embroidery.pinky.font": typed[i++]
} as const;

export { typed as SUMMARY_TREE };
