// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { StampColorRow } from '../typings';

const data = [
  {
    "id": "gold",
    "props": {
      "hex": "#be8738",
      "isMetallic": true,
      "colorId": "metallicGold",
      "tileImageSrc": "icons/metallicColor/metallicGold.png",
      "assetId": "Gold"
    },
    "name": "Gold",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  },
  {
    "id": "silver",
    "props": {
      "hex": "#a3a0a0",
      "isMetallic": true,
      "colorId": "metallicSilver",
      "tileImageSrc": "icons/metallicColor/metallicSilver.png",
      "assetId": "Platinum"
    },
    "name": "Platinum",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  },
  {
    "id": "scarlet",
    "props": {
      "hex": "#9e1714",
      "isMetallic": true,
      "colorId": "scarlet",
      "tileImageSrc": "icons/metallicColor/metallicScarlet.png",
      "assetId": "Scarlet"
    },
    "name": "Scarlet",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  },
  {
    "id": "royal",
    "props": {
      "hex": "#022c96",
      "isMetallic": true,
      "colorId": "royal",
      "tileImageSrc": "icons/metallicColor/metallicRoyal.png",
      "assetId": "Royal"
    },
    "name": "Royal",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  },
  {
    "id": "pink",
    "props": {
      "hex": "#b6475d",
      "isMetallic": true,
      "colorId": "pink",
      "tileImageSrc": "icons/metallicColor/metallicPink.png",
      "assetId": "Pink"
    },
    "name": "Pink",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  },
  {
    "id": "mint",
    "props": {
      "hex": "#278966",
      "isMetallic": true,
      "colorId": "mint",
      "tileImageSrc": "icons/metallicColor/mint.png",
      "assetId": "Mint"
    },
    "name": "Mint",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  },
  {
    "id": "columbiaBlue",
    "props": {
      "hex": "#1391b5",
      "isMetallic": true,
      "colorId": "columbiaBlue",
      "tileImageSrc": "icons/metallicColor/columbiaBlue.png",
      "assetId": "ColumbiaBlue"
    },
    "name": "ColumbiaBlue",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  },
  {
    "id": "purple",
    "props": {
      "hex": "#9831c2",
      "isMetallic": true,
      "colorId": "purple",
      "tileImageSrc": "icons/metallicColor/purple.png",
      "assetId": "Purple"
    },
    "name": "Purple",
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  },
  {
    "id": "indent",
    "props": {
      "isMetallic": false
    },
    "subsets": {
      "leatherId": {
        "pcfp": true
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<StampColorRow[]>> = data;

export type StampColor = typeof typed[number];

export const STAMP_COLOR_INDEX_KEY = "id";
export type StampColorIndexKey = "id";
export type StampColorId = StampColor["id"];

let i = 0;
export const STAMP_COLOR_DICT = {
  "gold": typed[i++],
  "silver": typed[i++],
  "scarlet": typed[i++],
  "royal": typed[i++],
  "pink": typed[i++],
  "mint": typed[i++],
  "columbiaBlue": typed[i++],
  "purple": typed[i++],
  "indent": typed[i++]
} as const;

export { typed as STAMP_COLORS };
