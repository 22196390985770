// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { GloveRow } from '../typings';

const data = [
  {
    "id": "120",
    "name": "120",
    "asset": {
      "gloveAssetId": "125SB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "pcfp": true
      },
      "fitIds": {
        "standard": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "weltingTypes": {
        "palm": true,
        "back": true
      },
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "ProI": true,
        "Basket": true,
        "ModTrapeze": true,
        "SinglePDB": false,
        "ProH": true,
        "ProV": true,
        "OnePS": false,
        "TwoPS": true,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "TwoPSJS": false,
        "Laced2PS": false,
        "Laced1PS": true
      }
    },
    "description": "Deep pocketed, standard hand spread. Very traditional softball pattern.",
    "props": {
      "sizeId": "12_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    }
  },
  {
    "id": "125",
    "name": "125",
    "asset": {
      "gloveAssetId": "125SB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "pcfp": true
      },
      "fitIds": {
        "standard": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false
      },
      "positionIds": {
        "infield": true,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "weltingTypes": {
        "palm": true,
        "back": true
      },
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "ProI": false,
        "Basket": true,
        "ModTrapeze": true,
        "SinglePDB": true,
        "ProH": true,
        "ProV": false,
        "OnePS": false,
        "TwoPS": true,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": false,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "TwoPSJS": false,
        "Laced2PS": false,
        "Laced1PS": true
      }
    },
    "description": "Deep pocketed, standard hand spread. Very traditional softball pattern. Closes thumb to middle and 4th finger.",
    "props": {
      "sizeId": "12_50",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    }
  },
  {
    "id": "130",
    "name": "130",
    "asset": {
      "gloveAssetId": "125KR",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "pcfp": true
      },
      "fitIds": {
        "standard": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false
      },
      "positionIds": {
        "infield": false,
        "outfield": true,
        "pitcher": true,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": true,
      "weltingTypes": {
        "palm": true,
        "back": true
      },
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "ProI": false,
        "Basket": true,
        "ModTrapeze": true,
        "SinglePDB": true,
        "ProH": true,
        "ProV": false,
        "OnePS": false,
        "TwoPS": true,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": false,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": true,
        "ModProH": false,
        "DoubleLacedBasket": true,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "TwoPSJS": false,
        "Laced2PS": false,
        "Laced1PS": true
      }
    },
    "description": "Deep pocketed, standard hand spread. Very traditional softball pattern. Closes thumb to middle and 4th finger.",
    "props": {
      "sizeId": "13_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProH"
    }
  },
  {
    "id": "715",
    "name": "715",
    "asset": {
      "gloveAssetId": "715SB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "CV",
      "leatherId": {
        "pcfp": true
      },
      "fitIds": {
        "standard": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false
      },
      "positionIds": {
        "infield": true,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": true
      },
      "fingerHood": true,
      "weltingTypes": {
        "palm": true,
        "back": true
      },
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": true,
        "ProI": true,
        "Basket": true,
        "ModTrapeze": true,
        "SinglePDB": false,
        "ProH": true,
        "ProV": true,
        "OnePS": false,
        "TwoPS": true,
        "SinglePDBbase": false,
        "BasketConnector": true,
        "VerticalH": true,
        "ModSinglePost": true,
        "VerticalHB": true,
        "ModTrapezeLoop": true,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "XLacedSingleP": true,
        "SinglePwXLace": true,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "TwoPSJS": false,
        "Laced2PS": false,
        "Laced1PS": true
      }
    },
    "description": "Most shallow pocket and standard hand spread. Closes thumb to 4th finger.",
    "props": {
      "sizeId": "11_75",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProI"
    }
  },
  {
    "id": "19",
    "name": "19",
    "asset": {
      "gloveAssetId": "FM19SB",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "pcfp": true
      },
      "fitIds": {
        "standard": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": true,
        "catcher": false,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": false,
      "weltingTypes": {
        "palm": true,
        "back": false
      },
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "ProI": false,
        "Basket": false,
        "ModTrapeze": false,
        "SinglePDB": false,
        "ProH": false,
        "ProV": false,
        "OnePS": false,
        "TwoPS": false,
        "SinglePDBbase": true,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": true,
        "DoubleLacedBasket": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": false,
        "TwoPSCatcher": false,
        "TwoPSJS": false,
        "Laced2PS": false,
        "Laced1PS": false
      }
    },
    "description": "A deep pocket with wide with and pull strap wrist closure",
    "props": {
      "sizeId": "13_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "SinglePDBbase"
    }
  },
  {
    "id": "CM34",
    "name": "CM34",
    "asset": {
      "gloveAssetId": "CM33FP",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "pcfp": true
      },
      "fitIds": {
        "standard": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": false,
      "weltingTypes": {
        "palm": true,
        "back": false
      },
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "ProI": false,
        "Basket": false,
        "ModTrapeze": false,
        "SinglePDB": false,
        "ProH": false,
        "ProV": false,
        "OnePS": false,
        "TwoPS": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": true,
        "TwoPSCatcher": true,
        "TwoPSJS": false,
        "Laced2PS": false,
        "Laced1PS": false
      }
    },
    "description": "A traditional softball catcher's mitt specific to a 34\" design. Features a deep pocket and standard width.",
    "props": {
      "sizeId": "34_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProHCatcher"
    }
  },
  {
    "id": "RUDI",
    "name": "RUDI",
    "asset": {
      "gloveAssetId": "PCFPRUDI",
      "useStitchingInsteadOfWelting": false
    },
    "limitations": {
      "trims": [
        "1"
      ],
      "sportId": "softball",
      "shellBackId": "PS",
      "leatherId": {
        "pcfp": true
      },
      "fitIds": {
        "standard": true
      },
      "throwingHandIds": {
        "left": true,
        "right": true
      },
      "shellBackDesignIds": {
        "leather": true,
        "deco": false,
        "pro": false
      },
      "positionIds": {
        "infield": false,
        "outfield": false,
        "pitcher": false,
        "firstBase": false,
        "catcher": true,
        "training": false
      },
      "fingerIds": {
        "index": true,
        "middle": false
      },
      "fingerHood": false,
      "weltingTypes": {
        "palm": true,
        "back": false
      },
      "halfMeshEndsOnIndexFinger": false,
      "webIds": {
        "SingleP": false,
        "ProI": false,
        "Basket": false,
        "ModTrapeze": false,
        "SinglePDB": false,
        "ProH": false,
        "ProV": false,
        "OnePS": false,
        "TwoPS": false,
        "SinglePDBbase": false,
        "BasketConnector": false,
        "VerticalH": false,
        "ModSinglePost": false,
        "VerticalHB": false,
        "ModTrapezeLoop": false,
        "Ypost": false,
        "ModProH": false,
        "DoubleLacedBasket": false,
        "XLacedSingleP": false,
        "SinglePwXLace": false,
        "HorizontalXLaces": false,
        "Trapeze": false,
        "OnePSCatcher": false,
        "ProHCatcher": true,
        "TwoPSCatcher": false,
        "TwoPSJS": true,
        "Laced2PS": false,
        "Laced1PS": false
      }
    },
    "description": "The signature pattern of catching guru and Easton ambassador Jen Schroeder. 34” design with deep pocket and standard width.",
    "props": {
      "sizeId": "34_00",
      "pricingSchemeId": "regular",
      "isHidden": false
    },
    "defaults": {
      "webId": "ProHCatcher"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GloveRow[]>> = data;

export type Glove = typeof typed[number];

export const GLOVE_INDEX_KEY = "id";
export type GloveIndexKey = "id";
export type GloveId = Glove["id"];

let i = 0;
export const GLOVE_DICT = {
  "120": typed[i++],
  "125": typed[i++],
  "130": typed[i++],
  "715": typed[i++],
  "19": typed[i++],
  "CM34": typed[i++],
  "RUDI": typed[i++]
} as const;

export { typed as GLOVES };
