// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { VendorRow } from '../typings';

const data = [
  {
    "hostname": "gloves.custom.easton.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "easton",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://easton.rawlings.com/customglove",
    "twitterHandle": "rawlingssports",
    "currency": "USD",
    "returnToSiteUrl": "https://easton.rawlings.com",
    "returnToSiteName": "easton.com",
    "features": {
      "disableLogo": true
    }
  },
  {
    "hostname": "mylocker.gloves.custom.easton.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "easton",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://mylocker.rawlings.com/save.aspx?category=24",
    "currency": "USD",
    "returnToSiteUrl": "https://mylocker.rawlings.com",
    "returnToSiteName": "mylocker.rawlings.com",
    "features": {
      "disableLogo": true
    }
  },
  {
    "hostname": "staging.gloves.custom.easton.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "easton",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://staging-jts-teamsports.demandware.net/s/rawlings-consolidated/customglove",
    "twitterHandle": "rawlingssports",
    "currency": "USD",
    "returnToSiteUrl": "https://staging-jts-teamsports.demandware.net/",
    "returnToSiteName": "easton.com",
    "features": {
      "disableLogo": true
    }
  },
  {
    "hostname": "staging.mylocker.gloves.custom.easton.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "easton",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://mylocker.rawlings.com/save.aspx?category=24",
    "twitterHandle": "rawlingssports",
    "currency": "USD",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://mylocker.rawlings.com",
    "returnToSiteName": "mylocker.rawlings.com",
    "features": {
      "disableLogo": true
    }
  },
  {
    "hostname": "rawlings-easton.cdev.orangelv.com",
    "environment": "development",
    "sportId": "bbsb",
    "vendorId": "easton",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://easton.rawlings.com/customglove",
    "currency": "USD",
    "features": {
      "disableLogo": true
    }
  },
  {
    "hostname": "development.gloves.custom.easton.com",
    "environment": "development",
    "sportId": "bbsb",
    "vendorId": "easton",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyFlat",
    "addToCartUrl": "https://easton.rawlings.com/customglove",
    "currency": "USD",
    "features": {
      "disableLogo": true
    }
  },
  {
    "hostname": "development.mylocker360.gloves.custom.easton.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "easton",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://localhost:44375/rawlingsmylocker/capturedesign",
    "twitterHandle": "eastonbaseball",
    "currency": "USD",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://localhost:44375",
    "returnToSiteName": "https://localhost:44375",
    "features": {
      "disableLogo": true
    }
  },
  {
    "hostname": "test.mylocker360.gloves.custom.easton.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "easton",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://api-rwls-qa-np.amla.io/rawlingsmylocker/capturedesign",
    "twitterHandle": "eastonbaseball",
    "currency": "USD",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://mylocker-rwls-qa-np.amla.io",
    "returnToSiteName": "https://mylocker-rwls-qa-np.amla.io",
    "features": {
      "disableLogo": true
    }
  },
  {
    "hostname": "staging.mylocker360.gloves.custom.easton.com",
    "environment": "staging",
    "sportId": "bbsb",
    "vendorId": "easton",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://api-rwls-np.amla.io/rawlingsmylocker/capturedesign",
    "twitterHandle": "eastonbaseball",
    "currency": "USD",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://mylocker-rwls-qa-np.amla.io",
    "returnToSiteName": "https://mylocker-rwls-qa-np.amla.io",
    "features": {
      "disableLogo": true
    }
  },
  {
    "hostname": "mylocker360.gloves.custom.easton.com",
    "environment": "production",
    "sportId": "bbsb",
    "vendorId": "easton",
    "isDisabled": false,
    "orderMethod": "client-cart",
    "orderDataFormat": "legacyNested",
    "addToCartUrl": "https://api.mylocker360.rawlings.com/rawlingsmylocker/capturedesign",
    "twitterHandle": "eastonbaseball",
    "currency": "USD",
    "orderButtonText": "Save",
    "returnToSiteUrl": "https://mylocker360.rawlings.com",
    "returnToSiteName": "https://mylocker360.rawlings.com",
    "features": {
      "disableLogo": true
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<VendorRow[]>> = data;

export type Vendor = typeof typed[number];

export const VENDOR_INDEX_KEY = "hostname";
export type VendorIndexKey = "hostname";
export type VendorHostname = Vendor["hostname"];

let i = 0;
export const VENDOR_DICT = {
  "gloves.custom.easton.com": typed[i++],
  "mylocker.gloves.custom.easton.com": typed[i++],
  "staging.gloves.custom.easton.com": typed[i++],
  "staging.mylocker.gloves.custom.easton.com": typed[i++],
  "rawlings-easton.cdev.orangelv.com": typed[i++],
  "development.gloves.custom.easton.com": typed[i++],
  "development.mylocker360.gloves.custom.easton.com": typed[i++],
  "test.mylocker360.gloves.custom.easton.com": typed[i++],
  "staging.mylocker360.gloves.custom.easton.com": typed[i++],
  "mylocker360.gloves.custom.easton.com": typed[i++]
} as const;

export { typed as VENDORS };
